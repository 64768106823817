.tile
{
    margin: 8px;
    background: transparent;
    font-size: inherit;
    width: 122px;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: flex-start;
}

.tile.inactive, .tile.add
{
    scale: 0.85;
    transition: scale 0.4s;
}

.tile:first-of-type, .tile:last-of-type
{
    margin-left: 0;
    margin-right: 0;
}

.tile ion-icon
{
    display: block;
    padding: 24px;
    border: 1px solid var(--ion-color-medium);
    border-radius: 50%;
    width: 48px;
    height: 48px;
    margin-bottom: 8px;
}

.tile.inactive ion-icon
{
    background: var(--ion-color-light);
    color: var(--ion-color-medium);
}

.tile.activating ion-icon, .tile:hover ion-icon
{
    background: var(--ion-color-primary-tint);
    color: var(--ion-color-light);
}

.tile.active ion-icon
{
    background: var(--ion-color-primary);
    color: var(--ion-color-light);
}

.tile.add ion-icon
{
    border-style: dashed;
}

.tile.add:hover ion-icon
{
    background: var(--ion-color-light);
    color: initial;
}