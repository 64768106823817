.item {
    height: 128px;
}

.image {
    width: 128px;
    height: 96px;
    border-radius: 16px;
    overflow: hidden;
    margin-top: 8px;
    margin-bottom: 8px;
}

.timestamp {
    position: absolute;
    top: 10px;
    inset-inline-end: 10px;
    font-size: 0.8rem;
}

.eventId {
    position: absolute;
    top: 50%;
    inset-inline-end: 10px;
    font-size: 0.8rem;
}

.chips {
    margin-left: -4px;
    display: flex;
    overflow-x: scroll;
}

.eventOutcome
{
    display: flex;
    align-items: center;
    color: var(--ion-color-shade);
    margin-bottom: 4px;
}

.eventOutcome ion-icon
{
    margin-right: 4px;
}
