.scanUX
{
    visibility: visible;
}

.scanUX ion-toolbar, .scanSlide .scanInstructions
{
    background: var(--ion-toolbar-background, #f7f7f7);
    opacity: 0.8;
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
}

.swiper
{
    height: 100%;
}

.scanSlide
{
    flex-direction: column;
}

.scanSlide .viewfinder
{
    width: 220px !important;
    height: 100%;
    color: yellow;
}

.scanSlide .scanInstructions
{
    flex-shrink: 0;
    width: 90%;
    max-width: 480px;
}

.scanWifiSlide
{
    display: block !important;
}

.scanWifiSlide .authIcon
{
    margin-right: 4px;
}

.credentialsSlide
{
    display: block !important;
}

.loadingItem
{
    --background: transparent;
}