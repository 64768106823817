.accountContent ion-button
{
    margin-left: 16px;
    margin-right: 16px;
}

.avatarContainer
{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.avatarContainer ion-avatar
{
    width: 96px;
    height: 96px;
}

.avatarContainer h1
{
    margin-top: 10px;
    margin-bottom: 0;
    font-size: large;
}

.avatarContainer h2
{
    margin-top: 8px;
    font-size: medium;
    font-weight: normal;
}