/* Hide all elements when the barcode scanner is active */
body.barcode-scanner-active {
    visibility: hidden;
    --background: transparent;
    --ion-background-color: transparent;
}

@media (min-width: 800px) {
    ion-app > div.ion-page {
        width: 800px;
        margin: auto;
    }

    .scroll-content {
        overflow-y: auto;
    }
}