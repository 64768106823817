.chips {
    margin-right: -4px;
    display: flex;
    flex-wrap: wrap;
}

.popover
{
    --width: 300px;
}

.tapable
{
    cursor: pointer;
}
