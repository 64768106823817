.transitPolicies ion-item
{
    --padding-start: 0;
    --inner-padding-end: 0;
}

.transitPolicies ion-item-divider
{
    margin-left: 16px;
    margin-right: 16px;
}

.transitPolicies div
{
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    overflow-x: scroll;
}

.jiraTickets ion-thumbnail
{
    --size: 32px;
}