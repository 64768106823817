.swiper
{
    width: 100%;
    height: 100%;
}

.swiper .slide
{
    display: block !important;
    text-align: initial;
}

.swiper .slide ion-note
{
    display: block;
}
